import React from 'react';

import { IJobOfferCard } from '../../models/job-offer.model';
import { useT } from '../../hooks/use-translation';

import DefaultCard from './default-card';

interface IJobOfferCardProps {
    className?: string;
    TitleTag?: React.ElementType;
    jobOfferCard: IJobOfferCard;
}

const JobOfferCard: React.FC<IJobOfferCardProps> = ({
    className = '',
    jobOfferCard,
    TitleTag = 'h2',
}) => {
    const { t } = useT();
    const { pathname, color, position, employmentForm } = jobOfferCard;

    return (
        <DefaultCard
            className={className}
            title={position || ''}
            description={employmentForm}
            pathname={pathname}
            color={color}
            buttonText={t('button.see.offer')}
            TitleTag={TitleTag}
        />
    );
};

export default JobOfferCard;
