// extracted by mini-css-extract-plugin
export var blue = "default-card-module--blue--PencD";
export var button = "default-card-module--button--wIVRP";
export var container = "default-card-module--container--6XbVN";
export var content = "default-card-module--content--5gGu7";
export var descriptionText = "default-card-module--description-text--+e9-O";
export var header = "default-card-module--header--Ruqig";
export var pink = "default-card-module--pink--cNi0-";
export var purple = "default-card-module--purple--VPha1";
export var titleText = "default-card-module--title-text--63E4g";
export var yellow = "default-card-module--yellow--a97Vt";