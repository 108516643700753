import React from 'react';
import { Link } from 'gatsby';

import {
    container,
    content,
    descriptionText,
    header,
    titleText,
    button,
    yellow,
    purple,
    pink,
    blue,
} from './default-card.module.scss';
import { TAccentColor } from '../../models/accent-color.model';

import Button from '../atoms/button';

interface IDefaultCardProps {
    className?: string;
    TitleTag?: React.ElementType;
    title: string;
    description?: string;
    pathname: string;
    buttonText?: string;
    color?: TAccentColor;
}

const DefaultCard: React.FC<IDefaultCardProps> = ({
    className = '',
    title,
    description,
    TitleTag = 'h2',
    buttonText,
    pathname,
    color = 'purple',
}) => {
    const colorClass = colorClasses[color];
    return (
        <Link to={pathname} className={`${container} ${className} ${colorClass}`}>
            <div className={content}>
                <div className={header}>
                    <TitleTag className={titleText}>{title}</TitleTag>
                    <p className={descriptionText}>{description}</p>
                </div>
                {buttonText && (
                    <Button className={button} as="element">
                        {buttonText}
                    </Button>
                )}
            </div>
        </Link>
    );
};

const colorClasses: Record<TAccentColor, string> = {
    yellow,
    purple,
    pink,
    blue,
};

export default DefaultCard;
