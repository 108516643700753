import React from 'react';
import { graphql } from 'gatsby';

import { layout, hero, section, list, item, card } from './career.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IJobOfferCard } from '../models/job-offer.model';
import { getNodes } from '../utils/get-nodes';
import { useT } from '../hooks/use-translation';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import Section from '../components/hoc/section';
import JobOfferCard from '../components/molecules/job-offer-card';

interface ICareerPageProps {
    readonly data: {
        allJobOffer: IQueryAllResult<IJobOfferCard>;
    };
}

const CareerPage: React.FC<ICareerPageProps> = ({ data }) => {
    const { t } = useT();
    const { allJobOffer } = data;

    const jobOffers = getNodes(allJobOffer);

    return (
        <MainLayout className={layout} showFloatingCircles={true}>
            <Hero
                className={hero}
                titleProps={{ children: t('career.hero.title'), Tag: 'h1' }}
                description={t('career.hero.content')}
                color="yellow"
            />
            <Section className={section} circleDisplay="none" gridType="narrow" height="auto">
                <ul className={list}>
                    {jobOffers.map((jobOffer) => {
                        return (
                            <li key={`job-item-${jobOffer.jobOfferId}`} className={item}>
                                <JobOfferCard className={card} jobOfferCard={jobOffer} />
                            </li>
                        );
                    })}
                </ul>
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query CareerPage($locale: String!) {
        allJobOffer(
            filter: { locale: { eq: $locale } }
            sort: { fields: [sequence], order: [ASC] }
        ) {
            edges {
                node {
                    ...jobOfferFields
                }
            }
        }
    }
`;

export default CareerPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
